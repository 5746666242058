import update from 'immutability-helper';
import { GET_USERS_LIST, ADD_USER, EDIT_USER } from '../actions/actionType';
import { initialState } from '../initialState';

function userReducer(state = initialState, action) {
  switch (action.type) {
    case GET_USERS_LIST:
      return update(state, {
        users: { $set: action.payload },
      });
    case ADD_USER:
      return update(state, {
        users: { $push: action.payload },
      });
    case EDIT_USER:
      let usersCopy = state.users;
      usersCopy = usersCopy.map((user) => {
        if (user.Id === action.payload.Id) return action.payload;
        return user;
      });
      return update(state, {
        users: { $set: usersCopy },
      });
    default:
      return state;
  }
}

export default userReducer;
