export const initialState = {
  profile: {},
  onboarding: {
    step: 1,
    tipeUsaha: 'personal-partner',
    metodePembayaran: [],
    selectedPaymentMethod: [],
    selectedPaymentChannel: [],
    informasiBisnis: {
      BizTypeCode: '',
      BizCategoryCode: '',
      BizUrl: '',
      IsDeveloper: false,
      PicFinanceEmail: '',
      PicFinancePhone: '',
      PicTechEmail: '',
      PicTechPhone: '',
      OwnerAddress: '',
      OwnerZip: '',
      OwnerPhone: '',
      OwnerNationality: '',
      IdCardPhoto: null,
      IdCardPhotoFile: null,
      IdCardName: '',
      IdCardNumber: '',
      NpwpPhoto: null,
      NpwpPhotoFile: null,
      NpwpName: '',
      NpwpNumber: '',
      NpwpAdress: '',
      AktaPhoto: null,
      AktaPhotoFile: null,
      SkMenkehPhoto: null,
      SkMenkehPhotoFile: null,
      AreaIsIndonesia: 1,
      AreaOthers: '',
      ApiDevUrl: '',
      SocialMediaUrl: '',
      MarketplaceUrl: '',
      Note: '',
    },
    detailBank: {
      BankName: '',
      BankAccountName: '',
      BankAccountNumber: '',
      BankAccountPhoto: '',
    },
    bizType: [],
    bizCategory: [],
  },
  key: {
    api_key: '',
    api_secret: '',
    api_public: '',
  },
  merchant: {},
  users: [],
  ipWhitelist: [],
  merchantPreview: {
    id: null,
    merchant: {
      id: null,
      code: null,
      name: null,
      address: null,
      city: null,
      province: null,
      country: null,
      zip: null,
      api_key: null,
      api_secret: null,
      api_public: null,
      payment: {
        method: null,
        channel: null,
      },
      bri: {
        virtual_account: null,
        institution_code: null,
      },
      espay_code: null,
      artajasa_merchant_id: null,
      callback: {
        inquiry: null,
        notification: null,
      },
      redirect_finish_url: null,
      status: 0,
      date_insert: null,
      date_update: null,
    },
    bank: {
      name: null,
      account: {
        number: null,
        name: null,
      },
    },
    biz: {
      type_code: null,
      category_code: null,
      url: '',
      is_developer: true,
      pic: {
        finance: {
          email: '',
          phone: '',
        },
        tech: {
          email: '',
          phone: '',
        },
      },
    },
    owner: {
      address: '',
      zip: '',
      phone: '',
      nationality: '',
    },
    document: {
      id_card: {
        photo: {
          url: null,
        },
        name: '',
        number: '',
      },
      tax_id_number: {
        photo: {
          url: null,
        },
        name: '',
        number: '',
        address: '',
      },
      akta: {
        photo: {
          url: null,
        },
      },
      sk_menkeh: {
        photo: {
          url: null,
        },
      },
    },
    area: {
      is_indonesia: true,
      other: null,
    },
    url: {
      api_dev: null,
      social_media: null,
      marketplace: null,
    },
    note: null,
    date: {
      registration: null,
      validated: null,
      approved: null,
    },
    status: 0,
  },
  themeAndLogo: {
    id: '',
    theme: '',
    logo: '',
  },
  paymentChannel: [],
  paymentMethod: [],
};
