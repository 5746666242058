const Tawkjs = (propertyId, key, name, email) => {
  if (!window) throw new Error('DOM is unavailable');

  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_LoadStart = new Date();
  window.Tawk_API.onLoad = () => {
    window.Tawk_API.setAttribute({ name, email }, (err) => new Error(err));
  };

  const tawkScript = document.getElementById('tawkScript');
  if (tawkScript) return window.Tawk_API;

  const tawkElement = document.createElement('script');
  tawkElement.id = 'tawkScript';
  tawkElement.async = 'true';
  tawkElement.src = `https://embed.tawk.to/${propertyId}/${key}`;
  tawkElement.setAttribute('crossorigin', '*');

  const firstScriptTag = document.getElementsByTagName('script')[0];
  if (!firstScriptTag || !firstScriptTag.parentNode) throw new Error('DOM is unavailable');

  firstScriptTag.parentNode.insertBefore(tawkElement, firstScriptTag);
};

export default Tawkjs;
