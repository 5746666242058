import update from 'immutability-helper';
import { GET_KEY, UPDATE_MERCHANT_ACCOUNT } from '../actions/actionType';
import { initialState } from '../initialState';

function keyReducer(state = initialState, action) {
  switch (action.type) {
    case GET_KEY:
      return update(state, {
        key: {
          api_key: { $set: action.payload.api_key !== undefined ? action.payload.api_key : '' },
          api_secret: { $set: action.payload.api_secret !== undefined ? action.payload.api_secret : '' },
          api_public: { $set: action.payload.api_public !== undefined ? action.payload.api_public : '' },
        },
        merchant: { $set: action.payload },
      });
    case UPDATE_MERCHANT_ACCOUNT:
      return update(state, {
        key: {
          api_key: { $set: action.payload.api_key !== undefined ? action.payload.api_key : '' },
          api_secret: { $set: action.payload.api_secret !== undefined ? action.payload.api_secret : '' },
          api_public: { $set: action.payload.api_public !== undefined ? action.payload.api_public : '' },
        },
        merchant: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default keyReducer;
