import { combineReducers } from 'redux';
import profileReducer from './profileReducer';
import onBoardingReducer from './onBoardingReducer';
import keyReducer from './keyReducer';
import userReducer from './userReducer';
import ipWhitelistReducer from './ipWhitelistReducer';
import qoinviewReducer from './qoinviewReducer';
import merchantReducer from './merchantReducer';
import withdrawalReducer from './withdrawalReducer';
import layoutReducer from './layoutReducer';
import { USER_LOGOUT } from '../actions/actionType';

const appReducer = combineReducers({
  profile: profileReducer,
  onboarding: onBoardingReducer,
  key: keyReducer,
  user: userReducer,
  ipWhitelist: ipWhitelistReducer,
  qoinview: qoinviewReducer,
  merchant: merchantReducer,
  withdrawal: withdrawalReducer,
  layout: layoutReducer,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
