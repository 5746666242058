import axios, { sandboxInstance } from './axios';
import { getSessionStorage, setSessionStorage, getCookie, removeSessionStorage } from '../utils/cookie';
import axios2 from 'axios';

export const config = (header = null) => {
  return {
    'Accept': 'application/json',
    'locale': 'en',
    'Authorization': `Bearer ${getSessionStorage('token-web-merchant')}`,
    'X-XSRF-TOKEN': getCookie('xsrf-token'),
    ...header,
  };
};

export const configTextPlain = () => {
  return {
    'Accept': 'application/json',
    'locale': 'en',
    'Authorization': `Bearer ${getSessionStorage('token-web-merchant')}`,
    'Content-Type': 'text/plain',
  };
};

export const configNoAuth = () => {
  return {
    Accept: 'application/json',
    locale: 'en',
  };
};

const configPut = () => {
  return {
    'Accept': 'application/json',
    'Authorization': `Bearer ${getSessionStorage('token-web-merchant')}`,
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

const configPutJson = () => {
  return {
    'Accept': 'application/json',
    'Authorization': `Bearer ${getSessionStorage('token-web-merchant')}`,
    'Content-Type': 'application/json',
    'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

const configPostJSON = () => {
  return {
    'Accept': 'application/json',
    'locale': 'en',
    'Authorization': `Bearer ${getSessionStorage('token-web-merchant')}`,
    'Content-Type': 'application/json',
    'X-XSRF-TOKEN': getCookie('xsrf-token'),
  };
};

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const isTokenExpired = /expired/g.test(error?.response?.data?.message?.toString().toLowerCase());
    if (error.response.status === 401 && isTokenExpired) {
      const originalRequest = error.config;
      const formData = new FormData();
      formData.append('RefreshToken', getSessionStorage('refresh-token-web-merchant'));
      await axios
        .post('client-manager-refresh-token', formData, {
          headers: config(),
        })
        .then((response) => {
          if (response.data.status === 'success') {
            setSessionStorage('refresh-token-web-merchant', response.data.data.RefreshToken);
            setSessionStorage('token-web-merchant', response.data.data.Token);
            originalRequest.headers['Authorization'] = 'Bearer ' + response.data.data.Token;
          } else {
            removeSessionStorage('token-web-merchant');
          }
          return axios(originalRequest);
        })
        .catch((err) => {
          return err.response;
        });
    }
    return error.response;
  }
);

export const apiGet = (url, isProduction = false) => {
  if (isProduction) {
    const response = axios
      .get(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  } else {
    const response = sandboxInstance
      .get(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });
    return response;
  }
};

export const apiGetWithBody = async (url, body) => {
  const qs = require('qs');
  const response = await axios2
    .get(url, {
      params: body,
      paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
      headers: configPostJSON(),
    })
    .then((response) => response)
    .catch((err) => {
      return err.response;
    });
  return response;
};

export const apiPostNoAuth = (url, body, isProduction = false, isJson = false) => {
  if (isProduction) {
    const response = axios
      .post(url, body, {
        headers: isJson ? configPostJSON() : configNoAuth(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = sandboxInstance
      .post(url, body, {
        headers: isJson ? configPostJSON() : configNoAuth(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};

export const apiPost = (url, body, isProduction = false, isJson = false) => {
  if (isProduction) {
    const response = axios
      .post(url, body, {
        headers: isJson ? configPostJSON() : config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = sandboxInstance
      .post(url, body, {
        headers: isJson ? configPostJSON() : config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};

export const apiPost2 = (url, body, isProduction = false, header = null) => {
  if (isProduction) {
    const response = axios
      .post(url, body, {
        headers: config(header),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = sandboxInstance
      .post(url, body, {
        headers: config(header),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};

export const apiPostTextPlain = (url, body, isProduction = false, isJson = false) => {
  if (isProduction) {
    const response = axios
      .post(url, body, {
        headers: configTextPlain(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = sandboxInstance
      .post(url, body, {
        headers: configTextPlain(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};

export const apiDelete = (url, isProduction = false) => {
  if (isProduction) {
    const response = axios
      .delete(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = sandboxInstance
      .delete(url, {
        headers: config(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};

export const apiPut = (url, data, isProduction = false) => {
  if (isProduction) {
    const response = axios
      .put(url, data, {
        headers: configPut(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = sandboxInstance
      .put(url, data, {
        headers: configPut(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};

export const apiPutJson = (url, data, isProduction = false) => {
  if (isProduction) {
    const response = axios
      .put(url, data, {
        headers: configPutJson(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  } else {
    const response = sandboxInstance
      .put(url, data, {
        headers: configPutJson(),
      })
      .then((response) => response)
      .catch((err) => {
        return err.response;
      });

    return response;
  }
};
