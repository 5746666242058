import update from 'immutability-helper';
import { GET_IP_WHITELIST } from '../actions/actionType';
import { initialState } from '../initialState';

function ipWhitelistReducer(state = initialState, action) {
  switch (action.type) {
    case GET_IP_WHITELIST:
      const data = action.payload.ipWhiteList === '' ? [] : action.payload.ipWhiteList;
      return update(state, {
        ipWhitelist: {
          $set: data,
        },
      });
    default:
      return state;
  }
}

export default ipWhitelistReducer;
