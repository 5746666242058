// import 'react-app-polyfill/ie9';
// import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
// import './polyfill';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import './utils/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
const { store, persistor } = configureStore();

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    loadingComponent={<div>Loading...</div>} //If not pass, nothing appears at the time of new version check.
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </CacheBuster>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
