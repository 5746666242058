// auth
export const LOGIN = 'LOGIN';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const USER_LOGOUT = 'USER_LOGOUT';

// onboarding
export const TIPE_USAHA = 'TIPE_USAHA';
export const SET_STEP = 'SET_STEP';
export const SET_METODE_PEMBAYARAN = 'SET_METODE_PEMBAYARAN';
export const GET_METODE_PEMBAYARAN = 'GET_METODE_PEMBAYARAN';
export const GET_METODE_PEMBAYARAN_WITH_SELECTED = 'GET_METODE_PEMBAYARAN_WITH_SELECTED';
export const SET_PAYMENT_METHOD_SELECTED = 'SET_PAYMENT_METHOD_SELECTED';
export const SET_PAYMENT_CHANNEL_SELECTED = 'SET_PAYMENT_CHANNEL_SELECTED';
export const SET_INFORMASI_BISNIS = 'SET_INFORMASI_BISNIS';
export const SET_DETAIL_BANK = 'SET_DETAIL_BANK';
export const GET_BIZ_TYPE = 'GET_BIZ_TYPE';
export const GET_BIZ_CATEGORY = 'GET_BIZ_CATEGORY';

// KEY
export const GET_KEY = 'GET_KEY';
export const UPDATE_MERCHANT_ACCOUNT = 'UPDATE_MERCHANT_ACCOUNT';

// USER MANAGEMENT
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';

// IP WHITELIST
export const GET_IP_WHITELIST = 'GET_IP_WHITELIST';
export const ADD_IP_WHITELIST = 'ADD_IP_WHITELIST';

// Merchant Preview
export const GET_MERCHANT_PREVIEW = 'GET_MERCHANT_PREVIEW';

// Qoin View
export const GET_THEME_LOGO = 'GET_THEME_LOGO';
export const GET_PAYMENT_CHANNEL = 'GET_PAYMENT_CHANNEL';
export const GET_PAYMENT_CHANNEL_SELECTED = 'GET_PAYMENT_CHANNEL_SELECTED';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_PAYMENT_METHOD_SELECTED = 'GET_PAYMENT_METHOD_SELECTED';
export const GET_SELECTED_PAYMENT = 'GET_SELECTED_PAYMENT';
export const HANDLE_CHANGE_PAYMENT_CHANNEL = 'HANDLE_CHANGE_PAYMENT_CHANNEL';
export const HANDLE_CHANGE_PAYMENT_METHOD = 'HANDLE_CHANGE_PAYMENT_METHOD';

export const GET_MERCHANT = 'GET_MERCHANT';
export const DATA_ONBOARDING_EMPTY = 'DATA_ONBOARDING_EMPTY';
export const SET_IS_PRODUCTION = 'SET_IS_PRODUCTION';
export const SET_SUSPENDED_STATUS = 'SET_SUSPENDED_STATUS';

export const SET_SELECTED_PAYMENT_TYPE = 'SET_SELECTED_PAYMENT_TYPE';

export const SET_HEADER_TITLE = 'SET_HEADER_TITLE';
