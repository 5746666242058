import update from 'immutability-helper';
import {
  GET_THEME_LOGO,
  GET_PAYMENT_CHANNEL,
  HANDLE_CHANGE_PAYMENT_CHANNEL,
  GET_PAYMENT_CHANNEL_SELECTED,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SELECTED,
  HANDLE_CHANGE_PAYMENT_METHOD,
} from '../actions/actionType';
import { initialState } from '../initialState';

function qoinviewReducer(state = initialState, action) {
  switch (action.type) {
    case GET_THEME_LOGO:
      return update(state, {
        themeAndLogo: {
          $set: action.payload,
        },
      });
    case GET_PAYMENT_CHANNEL:
      let copyData = action.payload;
      copyData = copyData?.map((channel) => {
        return update(channel, {
          selected: { $set: false },
        });
      });
      return update(state, {
        paymentChannel: { $set: copyData },
      });
    case GET_PAYMENT_CHANNEL_SELECTED:
      const selectedChannel = action.payload.selectedChannel.split(';');
      copyData = action.payload.data;
      copyData = copyData.map((channel) => {
        if (selectedChannel.includes(channel.code)) {
          return update(channel, {
            selected: { $set: true },
          });
        } else {
          return update(channel, {
            selected: { $set: false },
          });
        }
      });
      return update(state, {
        paymentChannel: { $set: copyData },
      });
    case HANDLE_CHANGE_PAYMENT_CHANNEL:
      return update(state, {
        paymentChannel: { $set: action.payload },
      });
    case GET_PAYMENT_METHOD:
      copyData = action.payload;
      copyData = copyData.map((method) => {
        return update(method, {
          selected: { $set: false },
        });
      });
      return update(state, {
        paymentMethod: { $set: copyData },
      });
    case GET_PAYMENT_METHOD_SELECTED:
      const selectedMethod = action.payload.selectedMethod.split(';');
      copyData = action.payload.data;
      copyData = copyData.map((method) => {
        if (selectedMethod.includes(method.code)) {
          return update(method, {
            selected: { $set: true },
          });
        } else {
          return update(method, {
            selected: { $set: false },
          });
        }
      });
      return update(state, {
        paymentMethod: { $set: copyData },
      });
    case HANDLE_CHANGE_PAYMENT_METHOD:
      return update(state, {
        paymentMethod: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default qoinviewReducer;
