import { apiDelete, apiGet, apiPost, apiPut } from '../../api/apiFunction';
import { GET_USERS_LIST, EDIT_USER } from './actionType';
import endpoints from '../../api/endpoints';

export const getUsersList = (params = {}, envProd = true) => async (dispatch) => {
  // const response = await apiGet(`${process.env.REACT_APP_BASE_URL}client-manager-user`);
  const urlParams = new URLSearchParams();
  for (let [key, param] of Object.entries(params)) urlParams.set(key, param);

  const response = await apiGet(endpoints.clientManagerUser + '?' + urlParams.toString(), envProd);
  if (response.data.status === 'success') {
    await dispatch({
      type: GET_USERS_LIST,
      payload: response.data.data,
    });
  }

  return response;
};

export const getUserDetail = async (id, envProd = true) => {
  const response = await apiGet(`${endpoints.clientManagerUser}/${id}`, envProd);
  return response;
};

export const addUser = async (data, envProd = true) => {
  const response = await apiPost(endpoints.clientManagerUser, data, envProd);
  // if (response.data.status === 'success') {
  //   await dispatch({
  //     type: ADD_USER,
  //     payload: response.data.data,
  //   });
  // }

  return response;
};

export const editUser = (id, data, envProd = true) => async (dispatch) => {
  const qs = require('querystring');
  const response = await apiPut(`${endpoints.clientManagerUser}/${id}`, qs.stringify(data), envProd);
  if (response.data.status === 'success') {
    await dispatch({
      type: EDIT_USER,
      payload: response.data.data,
    });
  }

  return response;
};

export const getAllUserRole = async (id, envProd = true) => {
  const response = await apiGet(`${endpoints.clientManagerRoleUser}/${id}`, envProd);
  return response;
};

export const getActivityLog = async (params) => {
  const { page, limit, fullname, startDate, endDate } = params;

  const response = await apiGet(
    `${endpoints.activityLog}?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&fullname=${fullname}`,
    true
  );

  return response;
};

export const getUserProfiles = async () => {
  const response = await apiGet(endpoints.editProfile, true);
  return response;
};

export const deleteUser = async (id) => {
  const response = await apiDelete(`${endpoints.clientManagerUser}/${id}`, true);
  return response;
};
