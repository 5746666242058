import { lazy, Suspense, Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from 'reactstrap';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';

import PublicRoute from './components/PublicRoute';
import PrivateRoute from './components/PrivateRoute';
import Tawkjs from './utils/tawk';
import Referer from './views/Pages/Referer/Referer';
// import NewLayout from './containers/NewLayout';

const loading = () => (
  <div className='animated fadeIn pt-3 text-center'>
    <Spinner size='lg' color='warning'></Spinner>
  </div>
);

// Containers
// const DefaultLayout = lazy(() => import('./containers/DefaultLayout'));
const NewLayout = lazy(() => import('./containers/NewLayout'));

// Pages
const Login = lazy(() => import('./views/Pages/Login'));
const Register = lazy(() => import('./views/Pages/Register'));
const Page404 = lazy(() => import('./views/Pages/Page404'));
const Page500 = lazy(() => import('./views/Pages/Page500'));
const ForgotPassword = lazy(() => import('./views/Pages/ForgotPassword/ForgotPassword'));
const ValidateForgotPassword = lazy(() => import('./views/Pages/ForgotPassword/ValidateForgotPassword'));
const ValidateEmail = lazy(() => import('./views/Pages/ForgotPassword/ValidateEmail'));
// const ChangePassword = React.lazy(() => import('./views/Pages/ChangePassword/ChangePassword'));
const LandingPage = lazy(() => import('./views/Pages/LandingPage/LandingPage'));
const Onboarding = lazy(() => import('./views/Onboarding/Onboarding'));
const BerhasilBergabung = lazy(() => import('./views/Onboarding/BerhasilBergabung'));

class App extends Component {
  componentDidMount() {
    const profile = this.props.profile.profile;
    const { REACT_APP_TAWKJS_PROPERTYID: propertyId, REACT_APP_TAWKJS_KEYS: keys } = process.env;
    Tawkjs(propertyId, keys, profile?.Fullname, profile?.Email);
  }

  componentDidUpdate(prevProps) {
    const prevProfile = prevProps.profile.profile;
    const profile = this.props.profile.profile;
    if (prevProfile?.Id !== profile?.Id) {
      const { REACT_APP_TAWKJS_PROPERTYID: propertyId, REACT_APP_TAWKJS_KEYS: keys } = process.env;
      Tawkjs(propertyId, keys, profile?.Fullname, profile?.Email);
    }
  }

  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading()}>
          <Switch>
            <PublicRoute exact path='/login' name='Login Page' component={Login} />
            <PublicRoute exact path='/register' name='Register Page' component={Register} />
            <PublicRoute exact path='/forgot-password' name='Forgot Password' component={ForgotPassword} />
            <PublicRoute
              exact
              path='/forgot-password/validate'
              name='ValidateForgotPassword'
              component={ValidateForgotPassword}
            />
            <PublicRoute exact path='/email/validate' name='Validate Email' component={ValidateEmail} />
            <PrivateRoute exact path='/onboarding' name='Onboarding' component={Onboarding} />
            <PrivateRoute exact path='/berhasil-bergabung' name='Berhasil Bergabung' component={BerhasilBergabung} />
            {/* <Route
              exact
              path='/change-password'
              name='Change Password'
              render={(props) => <ChangePassword {...props} />}
            /> */}
            <PublicRoute exact path='/' name='Landing Page' component={LandingPage} />
            <Route exact path='/404' name='Page 404' render={(props) => <Page404 {...props} />} />
            <Route exact path='/500' name='Page 500' render={(props) => <Page500 {...props} />} />
            <Route exact path='/redirect' name='Referer' render={(props) => <Referer {...props} />} />
            <Route path='/' name='Home' render={(props) => <NewLayout {...props} />} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, null)(App);
