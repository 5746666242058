/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserProfiles } from '../../../redux/actions/userManagement';
import { getSessionStorage, setSessionStorage } from '../../../utils/cookie';
import { LOGIN } from '../../../redux/actions/actionType';
import { useHistory } from 'react-router-dom';

function Referer(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const oldToken = getSessionStorage('token-web-merchant') || '';
  const oldRefreshToken = getSessionStorage('refresh-token-web-merchant') || '';

  useEffect(() => {
    _loadQuery();
  }, []);

  const _loadQuery = async () => {
    const searchParam = new URLSearchParams(props.location.search);
    const to = searchParam.get('to');

    setSessionStorage('token-web-merchant', searchParam.get('token'));
    setSessionStorage('refresh-token-web-merchant', searchParam.get('refreshToken'));

    const response = await getUserProfiles();
    if (!response || response?.status !== 200 || response?.data?.data?.Client?.Type === 'internal') {
      setSessionStorage('token-web-merchant', oldToken);
      setSessionStorage('refresh-token-web-merchant', oldRefreshToken);

      history.replace('/');
      return;
    }

    setSessionStorage('merchant-id', response?.data?.data?.Client?.Id);
    await dispatch({
      type: LOGIN,
      payload: response?.data?.data,
    });

    history.replace(`${to}?fromPayout=1`);
  };

  return <div></div>;
}

export default Referer;
