const endpoints = {
  login: 'client-manager-login',
  register: 'client-manager-register',
  changePassword: 'client-manager-password',
  editProfile: 'client-manager-profile',
  initialForgotPassword: 'client-manager-password-forgot-initial',
  validateForgotPassword: 'client-manager-password-validate',
  validateEmail: 'client-manager-email-validate',
  dashboard: 'merchant-dashboard',
  ipWhitelist: 'merchant-manager-ip',
  clientManagerClient: 'client-manager-client',
  minio: 'download',
  clientManagerUser: 'client-manager-user',
  clientManagerRoleUser: 'client-manager-role-user',
  sandbox: {
    paymentMethod: 'sandbox/payment-method-go',
    paymentBizType: 'sandbox/payment-biz-type-go',
    paymentBizCategory: 'sandbox/payment-biz-category-go',
    onboarding: 'sandbox/merchant/onboarding',
    merchantAccount: 'sandbox/merchant/account',
    merchantTransaction: 'sandbox/merchant/transaction',
    merchantSettlement: 'sandbox/merchant/settlement',
    merchantWithdrawal: 'sandbox/merchant/withdrawal',
    merchantJournal: 'sandbox/merchant/journal-list',
    createPaymentLink: 'sandbox/paymentlink/order',
    getPaymentLink: 'sandbox/paymentlink/list',
    paymentLinkLanding: 'sandbox/paymentlink/landing',
  },
  production: {
    paymentMethod: 'payment-method-go',
    paymentBizType: 'payment-biz-type-go',
    paymentBizCategory: 'payment-biz-category-go',
    onboarding: 'merchant/onboarding',
    merchantAccount: 'merchantgo/account',
    merchantTransaction: 'merchantgo/transaction',
    merchantSettlement: 'merchant/settlement',
    merchantWithdrawal: 'merchantgo/withdrawal',
    merchantJournal: 'merchant/journal-list',
    createPaymentLink: 'paymentlink/order',
    getPaymentLink: 'paymentlink/list',
    paymentLinkLanding: 'paymentlink/landing',
    paymentLinkDetail: 'paymentlink/detail',
  },
  merchantSettlementIn: 'settlement-in/list',
  merchantSettlementOut: 'settlement-out/list',
  merchantJournalSummary: 'merchant/journal-summary',
  merchantManagerLogo: 'merchantgo-manager-logo',
  merchantManagerPaymentChannel: 'merchantgo-manager-payment-channel',
  merchantManagerPaymentMethod: 'merchantgo-manager-payment-method',
  merchantManagerSystemSetting: 'merchantgo-manager-system-setting',
  requestProduction: 'webadmin/merchant-biz/request-production',
  merchantBankInfo: 'merchant/merchant-withdrawalgo/bank-info',
  paymentType: 'merchant/merchant-withdrawalgo/payment-type',
  getSummary: 'merchant/merchant-withdrawalgo/summary',
  createWithdrawal: 'merchant/merchant-withdrawalgo/create',
  transactionDetail: 'merchant/transaction-detail',
  emailNotificationUpdate: 'merchant-manager-failed-email/update',
  deactivateMerchant: 'merchant/suspended',
  settlementOutDetail: 'settlement-out/detail',
  settlementInDetail: 'settlement-in/detail',
  merchantBalance: 'merchant/balance',
  activityLog: 'merchant/log/activity',
  disbursement: 'disbursement',
  disbursementBank: 'disbursement/bank',
  disbursementInquiry: 'disbursement/inquiry',
  disbursementTransfer: 'disbursement/transfer',
  disbursementBalance: 'disbursement/balance',
  createPaymentChannelConfig: 'merchant/payment-channel/custom-config/create',
  updatePaymentChannelConfig: '/merchant/payment-channel/custom-config/update',
  getMerchantPaymentChannelConfig: '/merchant/payment-channel/custom-config',
};

export default endpoints;
