import update from 'immutability-helper';
import { SET_SELECTED_PAYMENT_TYPE } from '../actions/actionType';

const initialState = {
  paymentType: [],
};

function index(state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_PAYMENT_TYPE:
      return update(state, {
        paymentType: {
          $set: action.payload,
        },
      });
    default:
      return state;
  }
}

export default index;
