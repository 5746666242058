import update from 'immutability-helper';
import { SET_HEADER_TITLE } from '../actions/actionType';

const initialState = {
  pageTitle: '',
};

function layoutReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HEADER_TITLE:
      return update(state, {
        pageTitle: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default layoutReducer;
